<template>
  <div class="epidemic" v-loading="loading">
    <div class="companyRegister" :class="LOCALE" v-if="showEle === 1">
      <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" :label-width="LOCALE === 'en'?'180px':'120px'" class="demo-ruleForm">
        <el-form-item :label="$t('Antiepidemic')" prop="delivery">
          <div class="companyName">
            {{
              $store.state.baseStore.userInfo.company_name_en|priorFormat($store.state.baseStore.userInfo.company_name_zh,LOCALE)
            }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('Antiepidemicdeeds')" prop="desc" :rules="[{ required: true, message: ' ', trigger: 'change' }]">
          <el-input style="width: 500px;" :placeholder="$t('Antiepidemicdeedsfive')" maxlength="500" show-word-limit type="textarea"
                    v-model="ruleForm.desc" :autosize="{ minRows: 6}"></el-input>
        </el-form-item>
        <el-form-item :label="$t('updateCompanyImage')" prop="" class="uploadImg" :rules="[{ required: true, message: ' ', trigger: 'change' }]">
          <div>
            <el-button type="text" @click="openUploadImg">{{$t('uploadImg')}}</el-button>
          </div>
          <div class="threeImg clearfix">
            <div class="item fl" v-for="(item,index) in selectImgList" :key="index">
              <el-image
                  style="width: 124px; height: 86px"
                  :src="item.url"
                  fit="contain"></el-image>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('updateCompanyVideo')" prop="" class="uploadImg">
          <div>
            <el-button type="text" @click="openUploadVideo">{{$t('uploadVideo')}}</el-button>
          </div>
          <div class="threeImg clearfix">
            <div class="item fl" v-for="(item,index) in selectVideoList" :key="index">
              <el-image
                  style="width: 124px; height: 86px"
                  :src="item.cover_url"
                  fit="contain"></el-image>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="footer-btn">
        <el-button size="small" @click="offDialog">{{$t('cancel')}}</el-button>
        <el-button type="primary" size="small" @click="submitForm('ruleForm')" :loading="submitFormLoading">{{$t('Confirmandsubmit')}}
        </el-button>
      </div>
    </div>
    <div class="companySuccess" v-if="showEle === 2">
      <div class="layout">
        <div class="title" v-if="showTitle">
          {{$t('registerSuccessthank')}}
        </div>
        <div class="title" v-else>
          {{$t('companyhasregister')}}
        </div>
        <div class="logo">
          <el-image
              style="width: 108px; height: 108px"
              :src="companyDetail.logo?companyDetail.logo:DEFAULT_LOGO"
              fit="scale-down"></el-image>
        </div>
        <div class="companyName">
          {{ companyDetail.company_name_en|priorFormat(companyDetail.company_name_zh,LOCALE) }}
        </div>
        <div class="address">
          <span class="one">
            {{ companyDetail.country|countryCityFormat(companyDetail.region_en,companyDetail.region_zh,LOCALE) }}
          </span>
          <span class="two">
            {{$t('Booth')}}：{{companyDetail.booth_no|textFormat}}
          </span>
        </div>
        <div class="rank">
          <span class="one" v-if="companyDetail.votes">
            {{$t('CurrentRanking')}}：{{ companyDetail.single_ranking||$t('notranking') }}
          </span>
          <span class="one" v-else>
            {{$t('CurrentRanking')}}：{{'-'}}
          </span>
          <span class="two">
            <el-image
                class="img-wrap"
                style="width: 14px; height: 14px"
                :src="xinxin"
                fit="scale-down"></el-image>
            <span>
              {{ companyDetail.votes|textFormat }}
            </span>
          </span>
        </div>
        <div class="content">
          <div class="heading" v-if="type === 1">
            {{$t('Protest')}}：
          </div>
          <div class="heading" v-if="type === 3">
            {{$t('companyDesc')}}：
          </div>
          <div class="text" v-if="type === 1">
            {{ companyDetail.extend|textFormat }}
          </div>
          <div class="text" v-if="type ===3">
            {{ companyDetail.profile_en|priorFormat(companyDetail.profile_zh,LOCALE) | fullTextFormat }}
          </div>
        </div>
        <div class="multimedia clearfix">
          <div class="item fl" v-for="(item) in companyDetail.video_list" :key="item.aliyun_id">
            <el-image
                @click="openVideoPlay(item)"
                style="width: 124px; height: 86px"
                :src="item.cover_url"
                fit="scale-down"></el-image>
          </div>
          <div class="item fl" v-for="(item,index) in companyDetail.pic_list" :key="index">
            <el-image
                style="width: 124px; height: 86px"
                :src="item.url"
                :preview-src-list="[item.url]"
                fit="scale-down"></el-image>
          </div>
        </div>
      </div>
      <div class="leftTop">
        <div class="num">
          {{$t('bh_Num')}}{{ companyDetail.number|textFormat }}
        </div>
<!--        <div class="qrcode">-->
<!--          <el-image-->
<!--              style="width: 60px; height: 60px"-->
<!--              src="url"-->
<!--              fit="scale-down"></el-image>-->
<!--        </div>-->
      </div>
    </div>
    <div class="noqualifications" v-if="showEle === 3">
      <div>
        {{$t('Noqualification')}}
      </div>
    </div>
    <!--所有图片-->
    <el-dialog :title="$t('addImage')" :append-to-body="true" :visible.sync="openImgDialog" @close="clearImgDialog" width="500px" class="dialog">
      <div class="position-one" v-loading="imgLoading">
        <div class="position" :class="LOCALE">
          {{$t('Chooseuptothree')}}
        </div>
        <div class="img-wrap clearfix">
          <div class="item fl" v-for="(item, index) in companyImgList" :key="index">
            <img
                class="successImg"
                v-if="item.checked"
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/registrationsuccess.png"
                alt
                srcset
            />
            <div class="itemBox">
              <el-image
                  style="width: 124px; height: 86px"
                  :src="item.url"
                  :fit="'contain'"
              ></el-image>
              <div class="itembtn" @click="checkImg(index)"></div>
            </div>
          </div>
          <div class="upload fl">
            <fileUpload
                ref="uploadComponent"
                :autoUpload="fileUploadOptions.autoUpload"
                :accept="fileUploadOptions.accept"
                @uploadCompleteOSS="uploadImg"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </fileUpload>
          </div>
        </div>
        <div class="footer-btn">
          <el-button size="small" @click="openImgDialog = false">{{$t('cancel')}}</el-button>
          <el-button type="primary" size="small" @click="saveCheckedImg">{{$t('save')}}</el-button>
        </div>
      </div>
    </el-dialog>
    <!--所有视频-->
    <el-dialog :title="$t('addVideo')" :append-to-body="true" :visible.sync="openVideoDialog" @close="clearVideoDialog"
              width="500px" class="dialog">
      <div class="position-one" v-loading="videoLoading">
        <div class="position" :class="LOCALE">
          {{$t('Chooseatmostone')}}
        </div>
        <div class="img-wrap clearfix" >
          <div class="item fl" v-for="(item, index) in companyVideoList" :key="index">
            <img
                class="successImg"
                v-if="item.checked"
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/registrationsuccess.png"
                alt
                srcset
            />
            <div class="itemBox">
              <el-image
                  style="width: 124px; height: 86px"
                  :src="item.cover_url"
                  :fit="'contain'"
              ></el-image>
              <div class="itembtn" @click="checkVideo(index)"></div>
            </div>
          </div>
          <div class="upload fl">
            <fileUpload
                :isSingleVideo="true"
                :companyId="USER_INFO.company_id"
                :isTranscode="false"
                @startUpdateVideo="startUpdateVideo"
                @VODcallbackComplete="VODcallbackComplete"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </fileUpload>
          </div>
        </div>
        <div class="footer-btn">
          <el-button size="small" @click="openVideoDialog = false">{{$t('cancel')}}</el-button>
          <el-button type="primary" size="small" @click="saveCheckedVideo">{{$t('save')}}</el-button>
        </div>
      </div>
    </el-dialog>
    <videoPlay ref="videoPlay"></videoPlay>
  </div>
</template>

<script>
import fileUpload from "~/baseComponents/fileUpload";
import videoPlay from '~/baseComponents/videoPlay'

export default {
  name: "companyRegister",
  components: {
    fileUpload,
    videoPlay
  },
  props: {
    type: {//1是抗疫企业3是物流人气企业
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      ruleForm: {
        desc: ''
      },
      rules: {
        desc: {require: true, message: '', trigger: 'blur'}
      },
      //当前展示哪一个
      showEle: 1,
      showTitle: true,
      previewList: [],
      openImgDialog: false,
      openVideoDialog: false,
      companyImgList: [],
      selectImgList: [],
      companyVideoList: [],
      selectVideoList: [],
      fileUploadOptions: {
        autoUpload: true,
        accept: ".jpg,.jpeg,.bmp,.png",
      },
      fileUploadOption: {
        accept: '.mp4, .avi, .rmvb, .ts, .flv, .wmv, video/*',
        autoUpload: true,
      },
      imgLoading: false,
      videoLoading: false,
      submitFormLoading: false,
      loading: false,
      companyDetail: {},
      //是否报名
      isRegister: false,
      xinxin: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/xinxin.png',
      priviewDialog: false,
    }
  },
  created() {
    if (this.ENROLL_INFO.is_paid === 0||(this.ENROLL_INFO.apply_type ===3||this.ENROLL_INFO.apply_type ===4)) {
      this.showEle = 3
    } else {
      if (this.type === 1) {
        this.judgeCompanyRegister()
      } else {
        this.showEle=2
        this.showTitle=false
        this.getCompanyDetail()
      }
    }
  },
  mounted() {
  },
  methods: {
    //判断公司是否报过名
    async judgeCompanyRegister() {
      this.loading = true
      let params = {}
      params.company_id = this.$store.state.baseStore.userInfo.company_id
      params.apply_type = 2
      let res = await this.$store.dispatch('register/judgeCompanyRegister', params)
      if (res.success) {
        this.showEle = 1
        this.showTitle = true
        this.loading = false
      } else {
        this.showTitle = false
        this.showEle = 2
        this.getCompanyDetail()
      }
    },
    clearImgDialog() {
      this.companyImgList = []
    },
    clearVideoDialog() {

    },
    openUploadImg() {
      this.openImgDialog = true
      this.getCompanyImageList()
    },
    openUploadVideo() {
      this.openVideoDialog = true
      this.getCompanyVideoList()
    },
    //获取公司的图片
    async getCompanyImageList() {
      this.imgLoading = true
      let params = {}
      params.company_id = this.$store.state.baseStore.userInfo.company_id
      params.picture_type = 1
      params.status=0
      try {
        let res = await this.$store.dispatch('register/getCompanyImageList', params)
        if (res.success) {
          res.data.forEach((ee, vv) => {
            this.selectImgList.forEach((e, v) => {
              if (ee.id === e.id) {
                ee.checked = true
              }
            })
          })
          this.imgLoading = false
          this.companyImgList = res.data
        }
      } catch (e) {

      }
    },
    uploadImg(result) {
      let data = [{url: ''}]
      data[0].name = result.name
      data[0].url = result.url
      data[0].picture_kind = 1
      this.saveCompanyImg(data)
    },
    //上传公司图片
    async saveCompanyImg(data) {
      let params = {}
      params.company_id = this.$store.state.baseStore.userInfo.company_id
      params.data_info = JSON.stringify(data)
      let res = await this.$store.dispatch('register/companyImageSave', params)
      if (res.success) {
        this.getCompanyImageList()
      }
    },
    checkImg(index) {
      this.companyImgList[index].checked = !this.companyImgList[index]
          .checked;
      this.$forceUpdate();
    },
    saveCheckedImg() {
      let checkArr = this.companyImgList.filter((item) => item.checked);
      if (checkArr.length > 3) {
        this.$message({
          message: this.$t('Chooseuptothree'),
          type: 'warning'
        });
        return
      }
      this.selectImgList = checkArr
      this.openImgDialog = false
    },
    checkVideo(index){
      this.companyVideoList[index].checked = !this.companyVideoList[index]
          .checked;
      this.$forceUpdate();
    },
    startUpdateVideo(){
      this.videoLoading = true
    },
    VODcallbackComplete(result) {
      this.getCompanyVideoList()
    },
    //获取公司视频
    async getCompanyVideoList() {
      this.videoLoading = true
      let params = {}
      params.company_id = this.$store.state.baseStore.userInfo.company_id
      params.status=0
      let res = await this.$store.dispatch('register/getCompanyVideoList', params)
      if (res.success) {
        this.videoLoading = false
        this.companyVideoList = res.data
      }
    },
    saveCheckedVideo() {
      let checkArr = this.companyVideoList.filter((item) => item.checked);
      if (checkArr.length > 1) {
        this.$message({
          message: this.$t('Chooseatmostone'),
          type: 'warning'
        });
        return
      }
      this.selectVideoList = checkArr
      this.openVideoDialog = false
    },
    fileUploadVideo() {
    },
    offDialog() {
      this.$emit('offDialog')
    },
    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormFn()
        } else {
          return false;
        }
      });
    },
    //公司报名
    async submitFormFn() {
      if (!this.selectImgList.length) {
        this.$message({
          message: this.$t('pleaseAddImage'),
          type: 'warning'
        });
        return
      }
      this.submitFormLoading = true
      let params = {}
      params.company_id = this.$store.state.baseStore.userInfo.company_id
      params.apply_type = 2
      params.extend = this.ruleForm.desc
      params.pic_meidia_ids = this.selectImgList.map(item => item.id).join(',')
      params.meidia_ids = this.selectVideoList.map(item => item.id).join(',')
      let res = await this.$store.dispatch('register/companyRegisterEpidemic', params)
      if (res.success) {
        this.submitFormLoading = false
        this.showEle = 2
        this.getCompanyDetail()
      }
    },
    //获取公司报名详情
    async getCompanyDetail() {
      this.loading = true
      let params = {}
      params.company_id = this.$store.state.baseStore.userInfo.company_id
      if (this.type === 1) {
        params.apply_type = 2
      } else if (this.type === 3) {
        params.apply_type = 1
      }
      params.meeting_id = this.MEETING_ID
      let res = await this.$store.dispatch('register/companyRegisterDetail', params)
      if (res.success) {
        this.loading = false
        this.companyDetail = res.data
      } else {
        this.loading = false
      }
    },
    openVideoPlay(item){
      this.$refs.videoPlay.startPlay(item.aliyun_id);
    }
  }
}
</script>

<style scoped lang="less">
.companyRegister {
  padding-right: 50px;
  &.en{
    padding-right: 0;
  }

  .demo-ruleForm {
    .companyName {
      font-size: 14px;
      font-weight: bold;
      color: #1F292E;
    }

    .uploadImg {
      .item {
        width: 124px;
        height: 86px;
        background: #f2f2f2;
        margin-right: 20px;
      }
    }
  }

  .footer-btn {
    margin-top: 5px;
    text-align: right;
  }
}
.companySuccess {
  position: relative;
  .layout {
    padding: 0 65px;
    text-align: center;
    .title {
      font-size: 24px;
      font-weight: 800;
      color: #333333;
      margin-bottom: 32px;
    }
    .logo {
      width: 100%;
      height: 108px;
      margin-bottom: 12px;
    }
    .companyName {
      font-size: 16px;
      font-weight: 800;
      color: #333333;
      margin-bottom: 8px;
    }

    .address {
      display: flex;
      justify-content: center;
      margin-bottom: 27px;

      .one {
        font-size: 14px;
        font-weight: 400;
        color: #7D8695;
        margin-right: 24px;
      }

      .two {
        font-size: 14px;
        font-weight: 400;
        color: #DD9740;
      }
    }

    .rank {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
      align-items: center;

      .one {
        margin-right: 56px;
        font-size: 14px;
        font-weight: 800;
        color: #1F292E;
      }

      .two {
        width: 119px;
        height: 30px;
        background: linear-gradient(180deg, #FDD689 0%, #FF9905 100%);
        border-radius: 15px;
        font-size: 14px;
        font-weight: 800;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;

        .img-wrap {
          margin-right: 5px;
        }
      }
    }

    .content {
      margin-bottom: 16px;

      .heading {
        font-size: 12px;
        color: #1F292E;
        font-weight: 600;

      }

      .text {
        font-size: 12px;
        font-weight: 400;
        color: #1F292E;
        text-align: left;
      }
    }

    .multimedia {
      .item {
        width: 124px;
        height: 86px;
        background: #f2f2f2;
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }

  .leftTop {
    position: absolute;
    top: -30px;
    left: 0;
    width: 78px;
    height: 158px;
    background: linear-gradient(180deg, #FFFADA 0%, rgba(255, 252, 231, 0) 100%);
    border-radius: 0px 0px 4px 4px;
    padding-top: 9px;

    .num {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-weight: 800;
      color: #FF9905;
      margin-bottom: 27px;
    }

    .qrcode {
      width: 100%;
      height: 60px;
      text-align: center;
    }
  }
}

.dialog {
  .position-one{
    position:relative;
  }
  .img-wrap {
    height: 300px;
    overflow-y: scroll;
    .item {
      width: 124px;
      height: 86px;
      box-sizing: border-box;
      margin-right: 20px;
      background: #f2f2f2;
      position: relative;
      margin-bottom: 10px;

      .itemBox {
        width: 124px;
        height: 86px;

        .itembtn {
          width: 124px;
          height: 86px;
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.3);
          color: #aaa;
          text-align: center;
        }
      }

      &:nth-child(3n) {
        margin-right: 0px;
      }

      &:hover {
        .itembtn {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.7);
          color: #fff;
        }
      }

      .successImg {
        position: absolute;
        width: 25px;
        height: 25px;
        right: 5px;
        top: 5px;
        z-index: 99;
      }
    }
  }

  .footer-btn {
    text-align: right;
  }

  .position {
    position: absolute;
    top: -58px;
    left: 103px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    &.en{
      left: 120px;
      top: -56px;
    }
  }

  .upload {
    .el-icon-plus {
      width: 124px;
      height: 86px;
      font-size: 50px;
      text-align: center;
      line-height: 86px;
      border: 1px dashed #ccc;
      color: #999;
      cursor: pointer;
    }
  }
}

.noqualifications {
  min-height: 250px;
  line-height: 250px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.imgPreivewDialog {
  /deep/ .el-dialog {
    border-radius: 0px;
    background: transparent;
  }

  /deep/ .el-dialog__header {
    background: transparent;
    border-radius: 0px;
    border-bottom: 0 none;
  }
}

.diaLogImage {
  background: transparent;

  /deep/ & > img {
    display: none;
  }
}
</style>
