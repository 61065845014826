<template>
  <div class="wrap">
    <div class="banner" :class="LOCALE">
      <div class="type">
        <div class="layout">
          <div class="company">
            <el-image
              style="width: 56px; height: 56px; margin-bottom: 18px"
              :src="url1"
              fit="scale-down"
            ></el-image>
            <div class="title">
              {{ $t("contributionenterprises") }}
            </div>
            <div class="btn" @click="goToDetail(1)">
              {{ $t("enterclickjoin") }}
            </div>
          </div>
          <div class="reward">
            <el-image
              style="width: 56px; height: 56px; margin-bottom: 18px"
              :src="url2"
              fit="scale-down"
            ></el-image>
            <div class="title">
              {{ $t("epidemichero") }}
            </div>
            <div class="btn" @click="goToDetail(2)">
              {{ $t("enterclickjoin") }}
            </div>
          </div>
          <div class="car">
            <el-image
              style="width: 70px; height: 56px; margin-bottom: 18px"
              :src="url3"
              fit="scale-down"
            ></el-image>
            <div class="title">
              {{ $t("logisticscompanies") }}
            </div>
            <div class="btn" @click="goToDetail(3)">
              {{ $t("enterclickjoin") }}
            </div>
          </div>
          <div class="female">
            <el-image
              style="width: 56px; height: 56px; margin-bottom: 18px"
              :src="url4"
              fit="scale-down"
            ></el-image>
            <div class="title">
              {{ $t("Logisticsbeauty") }}
            </div>
            <div class="btn" @click="goToDetail(4)">
              {{ $t("enterclickjoin") }}
            </div>
          </div>
          <div class="male">
            <el-image
              style="width: 56px; height: 56px; margin-bottom: 18px"
              :src="url5"
              fit="scale-down"
            ></el-image>
            <div class="title">
              {{ $t("Logisticsman") }}
            </div>
            <div class="btn" @click="goToDetail(5)">
              {{ $t("enterclickjoin") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content_area" :class="LOCALE">
      <div class="blueLine"></div>
      <div class="content">
        <div class="title">
          {{ $t("prizeSet") }}
        </div>
        <div class="one">
          {{ $t("my_activity_p1") }}
        </div>
        <div class="two">
          {{ $t("my_activity_p2") }}
        </div>
      </div>
      <div class="content">
        <div class="title">
          {{ $t("activityTime") }}
        </div>
        <div class="one">
          {{ $t("my_activity_p3") }}
        </div>
        <div class="two">
          {{ $t("my_activity_p4") }}
        </div>
      </div>
      <div class="content">
        <div class="title">
          {{ $t("award") }}
        </div>
        <div class="one">
          {{ $t("my_activity_p5") }}
        </div>
        <div class="two">
          {{ $t("my_activity_p6") }}
        </div>
        <div class="three">
          {{ $t("my_activity_p7") }}
        </div>
        <div class="four">
          {{ $t("my_activity_p8") }}
        </div>
      </div>
      <div class="content">
        <div class="title">
          {{ $t("signQualification") }}
        </div>
        <div class="one">
          {{ $t("my_activity_p9") }}
        </div>
        <div class="two">
          {{ $t("my_activity_p10") }}
        </div>
      </div>
      <div class="content">
        <div class="title">
          {{ $t("voteWay") }}
        </div>
        <div class="one">
          {{ $t("my_activity_p11") }}
        </div>
      </div>
      <div class="content">
        <div class="title">
          {{ $t("rankingRule") }}
        </div>
        <div class="one">
          {{ $t("my_activity_p12") }}
        </div>
        <div class="two">
          {{ $t("my_activity_p13") }}
        </div>
        <div class="three">
          {{ $t("my_activity_p14") }}
        </div>
        <div class="four">
          {{ $t("my_activity_p15") }}
        </div>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="728px">
      <companyRegister
        :type="type"
        @offDialog="offDialog"
        v-if="dialogVisible"
      />
    </el-dialog>
    <el-dialog
      :title="userTitle"
      :visible.sync="userRegisterDialog"
      width="728px"
    >
      <userRegister
        @clearUserDialog="clearUserDialog"
        :type="type"
        v-if="userRegisterDialog"
      />
    </el-dialog>
  </div>
</template>

<script>
import companyRegister from "~exp/components/rankingList/register/companyRegister";
import userRegister from "~exp/components/rankingList/register/userRegister";

export default {
  name: "activity",
  components: {
    companyRegister,
    userRegister,
  },
  data() {
    return {
      url1:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/company.png",
      url2:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/reward.png",
      url3:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/car.png",
      url4:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/female.png",
      url5:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/male.png",
      dialogVisible: false,  // dialog 的显示与隐藏
      userRegisterDialog: false,
      type: null,
      title: "",
      userTitle: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    goToDetail(type) {
      // let now = this.$moment().unix()
      // console.log(now);
      let data = this.$moment("2021-4-16").endOf("day").unix();
      if (this.$moment().unix() > data) {
        this.$message({
          message: this.$t("registrationhasended"),
          type: "warning",
        });
        return;
      }
      this.type = type;
      this.$store.commit("SET_ISAPPLY_SHOW", true);
      this.checkSignEnrollExhibitor({
        todo: () => {
          if (type === 1) {
            this.dialogVisible = true;
            this.title = this.$t("contributionenterprises");
          } else if (type === 2) {
            this.userRegisterDialog = true;
            this.userTitle = this.$t("epidemichero");
          } else if (type === 3) {
            this.dialogVisible = true;
            this.title = this.$t("logisticscompanies");
          } else if (type === 4) {
            this.userRegisterDialog = true;
            this.userTitle = this.$t("Logisticsbeauty");
          } else if (type === 5) {
            this.userRegisterDialog = true;
            this.userTitle = this.$t("Logisticsman");
          }
        },
        type: "enroll",
      });
    },
    offDialog() {
      this.dialogVisible = false;
    },
    clearUserDialog() {
      this.userRegisterDialog = false;
    },
  },
};
</script>

<style scoped lang="less">
.wrap {
  background: #f6f7f9;
  padding-bottom: 70px;

  .banner {
    position: relative;
    padding-top: 40.1%;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/votebanner.png");
    background-size: 100% 100%;
    margin-bottom: 35px;
    &.en {
      background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/votebanneren.png");
      background-size: 100% 100%;
    }
    .type {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .layout {
        width: 1300px;
        margin: 27% auto 0;
        display: flex;
        justify-content: space-around;

        > div {
          max-width: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .title {
          font-size: 20px;
          font-weight: 800;
          height: 50px;
          text-align: center;
        }

        .btn {
          width: 132px;
          height: 34px;
          line-height: 34px;
          border-radius: 17px;
          font-size: 14px;
          color: #ffffff;
          font-weight: bold;
          text-align: center;
          cursor: pointer;
        }

        .company {
          .title {
            color: #086ddf;
          }

          .btn {
            background: #086ddf;
            color: #ffffff;
          }
        }

        .reward {
          .title {
            color: #fe4431;
          }

          .btn {
            background: #fe4431;
          }
        }

        .car {
          .title {
            color: #ff823d;
          }

          .btn {
            background: #ff7f3c;
          }
        }

        .female {
          .title {
            color: #ff32ba;
          }

          .btn {
            background: #f833c5;
          }
        }

        .male {
          .title {
            color: #9a4835;
          }

          .btn {
            background: #9a4835;
          }
        }
      }
    }
  }

  .content_area {
    background: #ffffff;
    padding-bottom: 1px;

    &.en {
      padding: 0 50px;
    }

    .blueLine {
      width: 100%;
      height: 10px;
      background: #3489ff;
      margin-bottom: 31px;
    }

    .content {
      text-align: left;
      margin-bottom: 60px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      padding-left: 15px;

      .title {
        font-size: 24px;
        font-weight: 800;
        color: #333333;
        margin-bottom: 24px;
      }

      .one,
      .two,
      .three,
      .four {
        margin-bottom: 22px;
      }
    }
  }
}
</style>
