<template>
  <div class="user" v-loading="loading">
    <div class="userJoin" v-if="show === 1" v-loading="formLoading">
      <el-form
          size="small"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          :label-width="LOCALE === 'en'?'160px':'120px'"
      >
        <el-form-item :label="nameLable" prop="name" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
          <el-select v-model="ruleForm.name" :placeholder="$t('pleaseSelect')" style="width: 280px;"
                     @change="changeSelectUser" :no-data-text="$t('waitforapproval')">
            <el-option v-for="item in userOption" :key="item.user_id"
                       :label="item.user_name_en|priorFormat(item.user_name_zh, LOCALE)" :value="item.user_id">

            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('dylancompanyName')">
          <div>
            {{
              $store.state.baseStore.userInfo.company_name_en
                  | priorFormat(
                  $store.state.baseStore.userInfo.company_name_zh,
                  LOCALE
                  )
            }}
          </div>
        </el-form-item>
        <el-form-item :label="$t('position')" prop="position" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
          <el-input
              style="width:280px;"
              v-model="ruleForm.position"
              :placeholder="$t('entercontent')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="eventLable" prop="desc" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
          <el-input
              :placeholder="descPlaceholder"
              style="width: 450px;"
              maxlength="200"
              type="textarea"
              v-model="ruleForm.desc"
              :autosize="{ minRows: 4 }"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('uploadImg')" prop="" class="uploadImg"
                      :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
          <div v-if="selectImgList.length < 3">
            <fileUpload
                ref="uploadComponent"
                :autoUpload="fileUploadOptions.autoUpload"
                :accept="fileUploadOptions.accept"
                @uploadCompleteOSS="uploadImg"
                :limit="3"
            >
              <el-button type="text">{{ $t('uploadImg') }}</el-button>
            </fileUpload>
          </div>
          <div v-else>
            <el-button type="text" :disabled="true">{{ $t('uploadImg') }}</el-button>
          </div>
          <div class="threeImg clearfix">
            <div
                class="item fl"
                v-for="(item, index) in selectImgList"
                :key="index"
            >
              <el-image
                  style="width: 124px; height: 86px"
                  :src="item.url"
                  fit="contain"
              ></el-image>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('uploadVideo')" prop="" class="uploadImg">
          <div v-if="selectVideoList.length < 1">
            <fileUpload
                :isSingleVideo="true"
                :companyId="USER_INFO.company_id"
                :isTranscode="false"
                @VODcallbackComplete="VODcallbackComplete"
                :isUserVideo="2"
                @startUpdateVideo="startUpdateVideo"
            >
              <el-button type="text">{{ $t('uploadVideo') }}</el-button>
            </fileUpload>
          </div>
          <div v-else>
            <el-button type="text" :disabled="true">{{ $t('uploadVideo') }}</el-button>
          </div>
          <div class="threeImg clearfix">
            <div
                class="item fl"
                v-for="(item, index) in selectVideoList"
                :key="index"
            >
              <el-image
                  style="width: 124px; height: 86px"
                  :src="item.cover_url"
                  fit="contain"
              ></el-image>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="footer-btn">
        <el-button size="small" @click="clearUserDialog">{{ $t('cancel') }}</el-button>
        <el-button
            type="primary"
            size="small"
            @click="userRegister('ruleForm')"
            :loading="registerLoading"
        >{{ $t('Confirmandsubmit') }}
        </el-button
        >
      </div>
    </div>
    <div class="signSuccess" v-if="show === 2">
      <div class="content">
        <div class="title" v-if="showTitle">
          {{ $t('registerSuccessthank') }}
        </div>
        <div class="title" v-else>
          {{ $t('whohaveregistered') }}
        </div>
        <!-- <div>
          <el-image
            style="width: 180px; height: 180px"
            src="url"
            fit="scale-down"
          ></el-image>
        </div> -->
        <!--        <div class="scanCode">-->
        <!--          {{ $t('ScanAndShare') }}-->
        <!--        </div>-->
      </div>
      <div class="footer">
        <el-button size='small' @click="clearUserDialog">{{ $t('cancel') }}</el-button>
        <el-button size="small" type="primary" @click="next" :loading="btnLoading">{{ $t('Keepadding') }}</el-button>
      </div>
    </div>
    <div class="full" v-if="show === 3">
      <div class="content">
        <div class="title">
          {{ $t('thenumberofcandidates') }}
        </div>
        <!--        <div>-->
        <!--          <div>-->
        <!--            <el-image-->
        <!--              style="width: 180px; height: 180px"-->
        <!--              src="url"-->
        <!--              fit="scale-down"-->
        <!--            ></el-image>-->
        <!--            <div>-->
        <!--              扫码即刻分享-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div>-->
        <!--            <el-image-->
        <!--              style="width: 180px; height: 180px"-->
        <!--              src="url"-->
        <!--              fit="scale-down"-->
        <!--            ></el-image>-->
        <!--            <div>-->
        <!--              扫码即刻分享-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="footer">
        <el-button size="small" type="primary" @click='clearUserDialog'>{{ $t('confirm') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import fileUpload from "~/baseComponents/fileUpload";
import {priorFormat} from "~/basePlugins/filters";

export default {
  name: "userRegister",
  components: {
    fileUpload,
  },
  props: {
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ruleForm: {
        name: null,
        position: null,
        desc: null,
      },
      rules: {},
      userOption: [],
      show: 1,
      openImgDialog: false,
      imgLoading: false,
      companyImgList: [],
      fileUploadOptions: {
        autoUpload: true,
        accept: ".jpg,.jpeg,.bmp,.png",
      },
      selectImgList: [],
      selectVideoList: [],
      nameLable: null,
      eventLable: null,
      registerLoading: false,
      showTitle: false,
      loading: false,
      btnLoading: false,
      descPlaceholder: '',
      formLoading:false,
    };
  },
  created() {
    if (this.type === 2) {
      this.nameLable = this.$t('epidemichero');
      this.eventLable = this.$t('Antiepidemicdeeds');
      this.descPlaceholder = this.$t('Pleaseenteranti');
    } else if (this.type === 4) {
      this.nameLable = this.$t('Logisticsbeauty');
      this.eventLable = this.$t('Personal_profile');
      this.descPlaceholder = this.$t('Pleaseenteaprofile')
    } else if (this.type === 5) {
      this.nameLable = this.$t('Logisticsman');
      this.eventLable = this.$t('Personal_profile');
      this.descPlaceholder = this.$t('Pleaseenteaprofile')
    }

    this.getCompanyRegisterPersonList();
    this.getCompanyNotRegister();
  },
  mounted() {
    
  },
  methods: {
    uploadImg(result) {
      let data = {url: ""};
      data.name = result.name;
      data.url = result.url;
      data.picture_kind = 2;
      this.selectImgList.push(data);
    },
    //获取公司未报名人员
    async getCompanyNotRegister() {
      let params = {};
      params.company_id = this.$store.state.baseStore.userInfo.company_id;
      if (this.type === 2) {
        params.apply_type = 1;
      } else if (this.type === 4) {
        params.apply_type = 3;
      } else if (this.type === 5) {
        params.apply_type = 2;
      }
      let res = await this.$store.dispatch(
          "register/getCompanyNotRegister",
          params
      );
      if (res.success) {
        this.userOption = res.data;
        let data = this.userOption.find(
            (item) => item.user_id === this.USER_ID
        );
        if (data) {
          this.ruleForm.name = this.USER_ID;
          this.ruleForm.position = priorFormat(
              data.job_title_en,
              data.job_title_zh,
              this.LOCALE
          );
        } else {
          if (this.userOption.length > 0) {
            this.ruleForm.name = this.userOption[0].user_id
            this.ruleForm.position = priorFormat(this.userOption[0].job_title_en, this.userOption[0].job_title_zh, this.LOCALE)

          }
        }
      }
    },
    //公司是否有人报名
    async getCompanyRegisterPersonList() {
      this.loading = true
      let params = {};
      params.company_id = this.$store.state.baseStore.userInfo.company_id;
      if (this.type === 2) {
        params.apply_type = 1;
      } else if (this.type === 4) {
        params.apply_type = 3;
      } else if (this.type === 5) {
        params.apply_type = 2;
      }
      let res = await this.$store.dispatch(
          "register/getCompanyRegisterPersonList",
          params
      );
      if (res.success) {
        this.loading = false
        if (res.data.length === 0) {
          this.show = 1;
        } else if (res.data.length === 1) {
          this.show = 2
        } else if (res.data.length === 2) {
          this.show = 3
        }
      }
    },
    //选中某个人
    changeSelectUser(val) {
      let data = this.userOption.find((item) => item.user_id === val);
      if (data) {
        this.ruleForm.position = priorFormat(
            data.job_title_en,
            data.job_title_zh,
            this.LOCALE
        );
      }
    },
    clearUserDialog() {
      this.$emit("clearUserDialog");
    },
    startUpdateVideo(){
      this.formLoading=true
    },
    VODcallbackComplete(result) {
      this.formLoading=false
      this.selectVideoList.push(result);
      // this.saveVideo(result)
    },
    //个人报名
    async userRegister(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.selectImgList.length) {
            this.$message({
              message: this.$t('pleaseAddImage'),
              type: 'warning'
            });
            return
          }
          this.registerLoading = true;
          //上传公司图片
          let params = {};
          params.company_id = this.$store.state.baseStore.userInfo.company_id;
          params.data_info = JSON.stringify(this.selectImgList);
            if (this.type === 2) {
              params.apply_type = 1;
            } else if (this.type === 4) {
              params.apply_type = 3;
            } else if (this.type === 5) {
              params.apply_type = 2;
            }
            params.apply_user_id = this.ruleForm.name;
            params.extend = this.ruleForm.desc;
            if (this.LOCALE === "en") {
              params.job_title_en = this.ruleForm.position;
            } else {
              params.job_title_zh = this.ruleForm.position;
            }
            if (this.selectVideoList.length) {
              params.aliyun_id = this.selectVideoList[0].aliyun_id;
            }
            let res = await this.$store.dispatch(
                "register/userRegisterCompanyMeeting",
                params
            );
            if (res.success) {
              this.registerLoading = false;
              this.show = 2;
              this.showTitle = true;
            } else {
              this.registerLoading = false;
            }
        } else {
          return false;
        }
      });
    },
    async next() {
      this.getCompanyNotRegister()
      this.show = 1
      this.ruleForm.name = null
      this.ruleForm.position = null
      this.ruleForm.desc = null
      this.selectVideoList = []
      this.selectImgList = []
      this.btnLoading = true
      let params = {};
      params.company_id = this.$store.state.baseStore.userInfo.company_id;
      if (this.type === 2) {
        params.apply_type = 1;
      } else if (this.type === 4) {
        params.apply_type = 3;
      } else if (this.type === 5) {
        params.apply_type = 2;
      }
      let res = await this.$store.dispatch(
          "register/getCompanyRegisterPersonList",
          params
      );
      if (res.success) {
        this.btnLoading = false
        if (res.data.length < 2) {
          this.show = 1;
        } else {
          this.show = 3
        }
      }
    }
  },
};
</script>

<style scoped lang="less">
.userJoin {
  padding-left: 20px;
  padding-right: 50px;
}

.footer-btn {
  text-align: right;
}

.dialog {
  .img-wrap {
    height: 300px;

    .item {
      box-sizing: border-box;
      margin-right: 20px;
      background: #f2f2f2;
      position: relative;
      margin-bottom: 10px;

      .itemBox {
        width: 124px;
        height: 86px;

        .itembtn {
          width: 124px;
          height: 86px;
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.3);
          color: #aaa;
          text-align: center;
        }
      }

      &:nth-child(3n) {
        margin-right: 0px;
      }

      &:hover {
        .itembtn {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.7);
          color: #fff;
        }
      }

      .successImg {
        position: absolute;
        width: 25px;
        height: 25px;
        right: 5px;
        top: 5px;
        z-index: 99;
      }
    }
  }

  .footer-btn {
    text-align: right;
  }

  .position {
    position: absolute;
    top: 10px;
    left: 103px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }

  .upload {
    .el-icon-plus {
      width: 124px;
      height: 86px;
      font-size: 50px;
      text-align: center;
      line-height: 86px;
      border: 1px dashed #ccc;
      color: #999;
      cursor: pointer;
    }
  }
}

.demo-ruleForm {
  .uploadImg {
    .item {
      width: 124px;
      height: 86px;
      background: #f2f2f2;
      margin-right: 20px;
    }
  }
}

.signSuccess {
  .content {
    .title {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 10px;
    }

    .scanCode {
      text-align: center;
    }
  }

  .footer {
    margin-top: 20px;
    text-align: center;
  }
}

.full {
  .content {
    .title {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }

  .footer {
    text-align: center;
  }
}
</style>
